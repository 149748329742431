<template>
    <form ref="candidateResumeIntentionForm" v-loading="loading" class="candidate-resume-intention-form" id="candidate-resume-intention-form" autocomplete="off">
        <input type="hidden" name="Id" :value="formData.id">

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="title">期望职位</label>
            <div class="col-sm-12">
                <input
                    class="form-control"
                    v-model="formData.title"
                    id="title"
                    name="title"
                    type="text"
                    value="">
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="locationId">期望城市</label>
            <div class="col-sm-12">
                <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="startDate">薪资要求</label>
            <div class="col-sm-12 input-group">
                <input
                    class="form-control col-3"
                    v-model="formData.minSalary"
                    id="minSalary"
                    name="minSalary"
                    type="text"
                    value="">
                <span class="text-inline">K</span>
                <div class="v-line">-</div>
                <input
                    class="form-control col-3"
                    v-model="formData.maxSalary"
                    id="maxSalary"
                    name="maxSalary"
                    type="text"
                    value="">
                <span class="text-inline">K</span>
            </div>
        </div>


        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <!-- <div class="delete-btn" @click="_delete">删除本条</div> -->
            </div>
        </div>
    </form>
</template>

<script>

    import resumeItemType from '#/js/config/resumeItemType.json';
    import { min as getMin, max as getMax, positiveInteger } from '#/js/util/validators.js';
    import CitySelect from '#/component/common/city-select.vue';


    function processProvinceCityData(list) {
        list.forEach(v => {
            v.value = v.id;
            v.id = v.id;
            v.text = v.text;
            v.selected = false;
            v.disabled = v.disabled||false;
            v.showChildren = false;
            v.children = v.children || [];
            v.visible = true;
            processProvinceCityData(v.children);
        })
    }

    let defaultFormObj = {
        "id": "",
        "title": "",
        "locationId": "",
        "minSalary": "",
        "maxSalary": ""
    }

    export default {
        components: {
            CitySelect
        },
        data() {
            return {
                formData: Object.assign({}, defaultFormObj),
                type: resumeItemType.intentions,
                hasSetCityData: false,
                loading:false,
            }
        },
        props:{
            candidateId:String,
            editUrl:String,
            addUrl:String,
            isSaveToDb: {
                type: Boolean,
                default: true,
            },
        },
        mounted () {
            this.form = this.$refs.candidateResumeIntentionForm;
            this.citySelect = this.$refs.citySelect;
        },
        methods: {

            _cancel(){
                this.$emit('resume-intention-form-cancel');
                this.citySelect.reset();
            },

            _delete(){
                this._showConfirmDialog();
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
                this.citySelect.reset();
            },

            _refresh(){
                this.$emit('candidate-detail-refresh');
            },

            _submitValidate(){
                let formData = this.formData;
                let isValid = true;

                delete formData.__ko_mapping__;
                formData = this.trimValue(formData);

                for(var key in formData) {
                    let value = formData[key];
                    if (_isDangerousString(value)) {
                        isValid = false;
                        shortTips('您输入的内容包含非法字符，请重新输入');
                        break;
                    }

                    if(!isValid) break;

                    switch(key){
                        case 'title':
                            if(!value || !getMin(1,value)){
                                isValid = false;
                                shortTips('期望职位不能为空');
                            }else if(value && !getMax(128,value)){
                                isValid = false;
                                shortTips('期望职位最长支持128个字符');
                            }
                            break;
                        case 'locationId':
                            if(!value){
                                isValid = false;
                                shortTips('期望城市不能为空');
                            }
                            break;
                        case 'minSalary':
                            if(!positiveInteger(value) || !value){
                                isValid = false;
                                shortTips('薪资只能为正整数且不能为空');
                            }
                            break;
                        case 'maxSalary':
                            if(!positiveInteger(value) || !value){
                                isValid = false;
                                shortTips('薪资只能为正整数且不能为空');
                            }
                            if(parseInt(value)<parseInt(formData.minSalary)){
                                isValid = false;
                                shortTips('最大薪资不能小于最小薪资');
                            }
                            break;
                        default:
                            break;
                    }
                }

                if(isValid){
                    this.loading=true;
                    this._submitCandidateEdit(formData);
                }
            },

            _submitCandidateEdit(formData){
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                if(this.isSaveToDb) { 
                    _request({
                        url: postUrl,
                        method: 'POST',
                        data: {
                            id:this.candidateId,
                            type:this.type,
                            data:{
                                intention:formData
                            } 
                        }
                    }).then(res => {
                        this._cancel();
                        this._refresh();
                        shortTips(isEdit ? '编辑成功' : '添加成功');
                    }).finally(() =>{
                        this.loading=false;
                    });
                } else {
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.loading=false;

                    this.$emit('save-btn-callback', formData);
                }
                
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                Object.assign(this.formData, data);
                this.citySelect.setDefaultCity(this.formData.locationId);
            },

            handleCitySelected(province, city) {
                this.formData.locationId = city;
            },
        }
    }
</script>

<style lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-intention-form.scss';
    .candidate-resume-detail .standard-resume .candidate-resume-intention-form{
        float: none;
    }
</style>
