<template>
    <form ref="candidateResumeProjectForm" v-loading="loading" class="candidate-resume-project-form col-15" id="candidate-resume-project-form" autocomplete="off">
        <input type="hidden" name="Id" :value="formData.id">

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="projectName">项目名称</label>
            <div class="col-sm-12">
                <input
                    class="form-control"
                    v-model="formData.projectName"
                    id="projectName"
                    name="projectName"
                    type="text"
                    value="">
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="projectRole">项目角色</label>
            <div class="col-sm-12">
                <input
                    class="form-control"
                    v-model="formData.projectRole"
                    id="projectRole"
                    name="projectRole"
                    type="text"
                    value="">
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="startDate">开始时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    class="start-date-picker"
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="startDateOptions"
                >
                </el-date-picker>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="endDate">结束时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    ref="endDatePicker"
                    class="end-date-picker"
                    style="width: 100%"
                    v-model="formData.endDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="endDateOptions"
                >
                </el-date-picker>
            </div>
        </div>
        <div class="form-group col-14">
            <label class="control-label col-md-12" for="responsibility">项目职责</label>
            <div class="col-sm-12">
            <textarea
                rows="3"
                class="form-control"
                v-model="formData.responsibility"
                id="responsibility"
                name="responsibility"
                type="text"
                value=""></textarea>
            </div>
        </div>
        <div class="form-group col-14">
            <label class="control-label col-md-12" for="description">项目描述</label>
            <div class="col-sm-12">
            <textarea
                rows="3"
                class="form-control"
                v-model="formData.description"
                id="description"
                name="description"
                type="text"
                value=""></textarea>
            </div>
        </div>

        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <!-- <div class="delete-btn" @click="_delete">删除本条</div> -->
            </div>
        </div>
    </form>
</template>

<script>
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';

    const DEFAULT_DATERANGE_FORMAT = 'YYYY-MM';
    const DATE_MONTH_REG = /^[0-9]{4}[年.\-/]((0?[1-9])|(1[0-2]))月?$/;

    let defaultFormObj = {
        "id": "",
        "projectName": "",
        "projectRole": "",
        "startDate": "",
        "endDate": "",
        "description": "",
        "responsibility": ""
    }

    export default {
        data() {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObj),
                type: resumeItemType.projectExperiences,
                loading:false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                }
            }
        },
        props:{
            candidateId:String,
            editUrl:String,
            addUrl:String,
            isSaveToDb: {
                type: Boolean,
                default: true,
            },
        },
        mounted () {
            this.form = this.$refs.candidateResumeProjectForm;
        },
        methods: {

            _cancel(){
                this.$emit('resume-project-form-cancel');
            },

            _delete(){
                this._showConfirmDialog();
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
            },

            _refresh(){
                this.$emit('candidate-detail-refresh');
            },

            _submitValidate(){
                let formData = this.formData;
                let isValid = true;

                delete formData.__ko_mapping__;
                formData = this.trimValue(formData);

                for(var key in formData) {
                    let value = formData[key];
                    console.log(`k,v:`, key, value)
                    if (_isDangerousString(value)) {
                        isValid = false;
                        shortTips('您输入的内容包含非法字符，请重新输入');
                        break;
                    }

                    if(!isValid) break;

                    switch(key){
                        case 'projectName':
                            if(!value || !getMin(1,value)){
                                isValid = false;
                                shortTips('项目名称不能为空');
                            }else if(value && !getMax(128,value)){
                                isValid = false;
                                shortTips('项目名称最长支持128个字符');
                            }
                            break;
                        case 'startDate':
                            if(isNaN(new Date(value).getTime())){
                                isValid = false;
                                shortTips('开始时间日期格式不符');
                            }
                            break;
                        case 'endDate':
                            
                            let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                            if(endDateText != '至今') {
                                if(isNaN(new Date(value).getTime())){
                                    isValid = false;
                                    shortTips('结束时间日期格式不符');
                                }else if(new Date(value).getTime() < new Date(formData.startDate).getTime()){
                                    isValid = false;
                                    shortTips('结束时间应大于开始时间');
                                }
                            }
                            break;
                        case 'responsibility':
                            if(value && !getMax(2000,value)){
                                isValid = false;
                                shortTips('项目职责最长支持2000个字符');
                            }
                            break;
                        case 'description':
                            if(value && !getMax(8000,value)){
                                isValid = false;
                                shortTips('项目描述最长支持8000个字符');
                            }
                            break;
                        default:
                            break;
                    }
                }

                if(isValid){
                    this.loading=true;
                    this._submitCandidateEdit(formData);
                }
            },

            _submitCandidateEdit(formData){
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let projectExperience = {
                    ...formData
                };
                if(endDateText == '至今') {
                    projectExperience.endDate = '9999-12-31';
                }
                if(this.isSaveToDb) {
                    _request({
                        url: postUrl,
                        method: 'POST',
                        data: {
                            id:this.candidateId,
                            type:this.type,
                            data:{
                                projectExperience
                            }
                        }
                    }).then(res => {
                        this._cancel();
                        this._refresh();
                        shortTips(isEdit ? '编辑成功' : '添加成功');
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.loading = false;

                    this.$emit('save-btn-callback', projectExperience);
                }
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                Object.assign(this.formData, data);

                
                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? new Date(): this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? new Date(): this.formData.startDate;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import './candidate-resume-project-form.scss';
    .candidate-resume-project-form {
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
