var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeWorkForm",
      staticClass: "candidate-resume-work-form col-15",
      attrs: { autocomplete: "off", id: "candidate-resume-work-form" },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c(
        "div",
        { staticClass: "form-group col-14 flex-center" },
        [
          _c("el-checkbox", {
            staticClass: "last-experience-checkbox",
            attrs: { disabled: _vm.isCheckboxLocked, size: "medium" },
            model: {
              value: _vm.formData.isLatestWorkExperience,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "isLatestWorkExperience", $$v)
              },
              expression: "formData.isLatestWorkExperience",
            },
          }),
          _c("label", { staticClass: "label-select-text" }, [
            _vm._v("设为最近工作经历"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required before-none",
            attrs: { for: "company" },
          },
          [_vm._v("公司")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm.formData.company || _vm.formData.isAdd
              ? _c("customer-select-or-write", {
                  attrs: {
                    customerId: _vm.formData.customerId,
                    customerName: _vm.formData.company,
                  },
                  on: {
                    "update:customerId": function ($event) {
                      return _vm.$set(_vm.formData, "customerId", $event)
                    },
                    "update:customer-id": function ($event) {
                      return _vm.$set(_vm.formData, "customerId", $event)
                    },
                    "update:customerName": function ($event) {
                      return _vm.$set(_vm.formData, "company", $event)
                    },
                    "update:customer-name": function ($event) {
                      return _vm.$set(_vm.formData, "company", $event)
                    },
                    "finish-callback": _vm.finishCallback,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.formData.isLatestWorkExperience &&
      _vm.formData.customerId &&
      _vm.showDepart(_vm.formData.tempCustomerId, _vm.formData.customerId)
        ? _c("div", { staticClass: "form-group col-7" }, [
            _c(
              "label",
              {
                staticClass: "control-label before-none",
                attrs: { for: "customerDepartmentName" },
              },
              [_vm._v("部门")]
            ),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _vm.formData.isLatestWorkExperience && _vm.formData.customerId
                  ? _c("customer-department-select", {
                      ref: "departmentSelect",
                      attrs: {
                        canSrcoll: false,
                        customerId: _vm.formData.customerId,
                        departmentId: _vm.formData.customerDepartmentId,
                        customerDepartmentName:
                          _vm.formData.customerDepartmentName,
                        placeholder: '输入完整路径，多级部门用"，"分隔',
                      },
                      on: { "update-department": _vm.updateDepartment },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required before-none",
            attrs: { for: "title" },
          },
          [_vm._v("职位")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.title,
                expression: "formData.title",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "title", name: "title", type: "text", value: "" },
            domProps: { value: _vm.formData.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "title", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "startDate" } },
          [_vm._v("开始时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              staticClass: "start-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.startDateOptions,
              },
              model: {
                value: _vm.formData.startDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "startDate", $$v)
                },
                expression: "formData.startDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "endDate" } },
          [_vm._v("结束时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              ref: "endDatePicker",
              staticClass: "end-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.endDateOptions,
              },
              model: {
                value: _vm.formData.endDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "endDate", $$v)
                },
                expression: "formData.endDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "locationId" } },
          [_vm._v("地区")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("city-select", {
              ref: "citySelect",
              on: { citySelected: _vm.handleCitySelected },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "description" } },
          [_vm._v("工作内容")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.description,
                expression: "formData.description",
              },
            ],
            staticClass: "form-control",
            attrs: {
              rows: "3",
              id: "description",
              name: "description",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "description", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }