var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeEvaluationForm",
      staticClass: "candidate-resume-evaluation-form col-15",
      attrs: { id: "candidate-resume-evaluation-form", autocomplete: "off" },
    },
    [
      _c("div", { staticClass: "form-group col-14" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "selfEvaluation" },
          },
          [_vm._v("自我评价")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.selfEvaluation,
                expression: "formData.selfEvaluation",
              },
            ],
            staticClass: "form-control",
            attrs: {
              rows: "3",
              id: "selfEvaluation",
              name: "selfEvaluation",
              type: "text",
              value: "",
            },
            domProps: { value: _vm.formData.selfEvaluation },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "selfEvaluation", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }