var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "candidateResumeEducationForm",
      staticClass: "candidate-resume-education-form col-15",
      attrs: { id: "candidate-resume-education-form", autocomplete: "off" },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "Id" },
        domProps: { value: _vm.formData.id },
      }),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "school" },
          },
          [_vm._v("学校")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-autocomplete", {
              staticClass: "select-search select-search-school",
              attrs: {
                "fetch-suggestions": _vm.querySchoolSearch,
                placeholder: "请输入内容",
                "popper-class": "edit-dialog-autocomplete",
              },
              on: { select: _vm.handleSchool },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.formData.school,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "school", $$v)
                },
                expression: "formData.school",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label required col-md-12 none-before",
            attrs: { for: "degree" },
          },
          [_vm._v("学历")]
        ),
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.degree,
                  expression: "formData.degree",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "degree", name: "degree" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.formData,
                    "degree",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "0" } }, [_vm._v("未知")]),
              _c("option", { attrs: { value: "1" } }, [_vm._v("高中及以下")]),
              _c("option", { attrs: { value: "2" } }, [_vm._v("大专")]),
              _c("option", { attrs: { value: "3" } }, [_vm._v("本科")]),
              _c("option", { attrs: { value: "4" } }, [_vm._v("硕士")]),
              _c("option", { attrs: { value: "5" } }, [_vm._v("MBA")]),
              _c("option", { attrs: { value: "6" } }, [_vm._v("博士")]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          {
            staticClass: "control-label col-md-12",
            attrs: { for: "startDate" },
          },
          [_vm._v("开始时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              staticClass: "start-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.startDateOptions,
              },
              model: {
                value: _vm.formData.startDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "startDate", $$v)
                },
                expression: "formData.startDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label col-md-12", attrs: { for: "endDate" } },
          [_vm._v("结束时间")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-date-picker", {
              ref: "endDatePicker",
              staticClass: "end-date-picker",
              staticStyle: { width: "100%" },
              attrs: {
                type: "month",
                placeholder: "如 2018-08",
                "value-format": "yyyy-MM",
                "picker-options": _vm.endDateOptions,
              },
              model: {
                value: _vm.formData.endDate,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "endDate", $$v)
                },
                expression: "formData.endDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-7" }, [
        _c(
          "label",
          { staticClass: "control-label col-md-12", attrs: { for: "major" } },
          [_vm._v("专业")]
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("el-autocomplete", {
              staticClass: "select-search",
              attrs: {
                "fetch-suggestions": _vm.queryMajorSearch,
                placeholder: "请输入内容",
                "popper-class": "edit-dialog-autocomplete",
              },
              on: { select: _vm.handleMajor },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.formData.major,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "major", $$v)
                },
                expression: "formData.major",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "form-group col-14" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("input", {
            staticClass: "btn btn-primary submitBtn btn-background",
            attrs: { type: "button", value: "保存" },
            on: { click: _vm._submitValidate },
          }),
          _c("input", {
            staticClass: "btn btn-default cancelBtn",
            attrs: { type: "button", value: "取消" },
            on: { click: _vm._cancel },
          }),
        ]),
        _c("div", { staticClass: "col-sm-2 pull-right" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }