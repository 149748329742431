<template>
    <form ref="candidateResumeEducationForm" v-loading="loading" class="candidate-resume-education-form col-15" id="candidate-resume-education-form" autocomplete="off">
        <input type="hidden" name="Id" :value="formData.id">

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="school">学校</label>
            <div class="col-sm-12">
                <!-- <select-search ref="schoolSelectSearch" :keywordinfo="formData.school" @select-search-keywords-change="onchangeSchool" @select-search-inputEnter="onchangeSchool" @select-search-select='onchangeSchool' class="school-select-search"></select-search> -->
                <el-autocomplete 
                    class="select-search select-search-school" 
                    v-model="formData.school" 
                    :fetch-suggestions="querySchoolSearch" 
                    placeholder="请输入内容" 
                    @select="handleSchool" 
                    popper-class="edit-dialog-autocomplete"
                >
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.name }}</div>
                    </template>
                </el-autocomplete>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label required col-md-12 none-before" for="degree">学历</label>
            <div class="col-sm-12">
                <select
                    class="form-control"
                    v-model="formData.degree"
                    id="degree"
                    name="degree">
                    <option value="0">未知</option>
                    <option value="1">高中及以下</option>
                    <option value="2">大专</option>
                    <option value="3">本科</option>
                    <option value="4">硕士</option>
                    <option value="5">MBA</option>
                    <option value="6">博士</option>
                </select>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="startDate">开始时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    class="start-date-picker"
                    style="width: 100%"
                    v-model="formData.startDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="startDateOptions"
                >
                </el-date-picker>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="endDate">结束时间</label>
            <div class="col-sm-12">
                <el-date-picker
                    ref="endDatePicker"
                    class="end-date-picker"
                    style="width: 100%"
                    v-model="formData.endDate"
                    type="month"
                    placeholder="如 2018-08"
                    value-format="yyyy-MM"
                    :picker-options="endDateOptions"
                >
                </el-date-picker>
            </div>
        </div>

        <div class="form-group col-7">
            <label class="control-label col-md-12" for="major">专业</label>
            <div class="col-sm-12">
                <!-- <select-search ref="majorSelectSearch" :keywordinfo="formData.major" @select-search-keywords-change="onchangeMajor" @select-search-inputEnter="onchangeMajor" @select-search-select='onchangeMajor'  class="major-select-search"></select-search> -->
                <el-autocomplete 
                    class="select-search" 
                    v-model="formData.major" 
                    :fetch-suggestions="queryMajorSearch" 
                    placeholder="请输入内容" 
                    @select="handleMajor" 
                    popper-class="edit-dialog-autocomplete"
                >
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.name }}</div>
                    </template>
                </el-autocomplete>
            </div>
        </div>

        <div class="form-group col-14">
            <div class="col-sm-6">
                <input class="btn btn-primary submitBtn btn-background" type="button" value="保存" @click="_submitValidate">
                <input type="button" value="取消" class="btn btn-default cancelBtn" @click="_cancel">
            </div>
            <div class="col-sm-2 pull-right">
                <!-- <div class="delete-btn" @click="_delete">删除本条</div> -->
            </div>
        </div>
    </form>
</template>

<script>
    import { candidate as candidateConfig } from '#/js/config/api.json';
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';

    let defaultFormObj = {
        "id": "",
        "school": "",
        "degree": "",
        "startDate": "",
        "endDate": "",
        "major": ""
    }

    export default {
        components: {
            // SelectSearch
        },
        data() {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObj),
                universityUrl: candidateConfig.url_university_list,
                majorUrl: candidateConfig.url_major_list,
                type: resumeItemType.educations,
                majorList:[],
                universityList:[],
                loading:false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                }
            }
        },
        props:{
            candidateId: String,
            editUrl: String,
            addUrl: String,
            isSaveToDb: {
                type: Boolean,
                default: true,
            },
        },
        mounted () {
            this.form = this.$refs.candidateResumeEducationForm;
            // this.schoolSelect = this.$refs.schoolSelectSearch;
            // this.majorSelect = this.$refs.majorSelectSearch;
            this.initEvent();
        },
        methods: {
            _cancel(){
                this.$emit('resume-education-form-cancel');
            },

            _delete(){
                this._showConfirmDialog();
            },

            _reset(){
                this.formData = Object.assign({}, defaultFormObj);
            },

            _refresh(){
                this.$emit('candidate-detail-refresh')
            },

            _submitValidate(){
                let formData = this.formData;
                let isValid = true;

                delete formData.__ko_mapping__;
                formData = this.trimValue(formData);
                for(var key in formData) {
                    let value = formData[key];

                    if (_isDangerousString(value)) {
                        isValid = false;
                        shortTips('您输入的内容包含非法字符，请重新输入');
                        break;
                    }

                    if(!isValid) break;

                    if(value && !getMax(128,value?.toString())){
                        isValid = false;
                        shortTips('输入框最长支持128个字符');
                        break;
                    }

                    switch(key){
                        case 'school':
                            if(!value || !getMin(1,value)){
                                isValid = false;
                                shortTips('学校不能为空');
                            }
                            break;
                        case 'degree':
                            if(!value){
                                isValid = false;
                                shortTips('学历不能为空');
                            }
                            break;
                        case 'startDate':
                            if(isNaN(new Date(value).getTime())){
                                isValid = false;
                                shortTips('开始时间日期格式不符');
                            }
                            break;
                        case 'endDate':
                            let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                            if(endDateText != '至今') {
                                if(isNaN(new Date(value).getTime())){
                                    isValid = false;
                                    shortTips('结束时间日期格式不符');
                                }else if(new Date(value).getTime() < new Date(formData.startDate).getTime()){
                                    isValid = false;
                                    shortTips('结束时间应大于开始时间');
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }

                if(isValid){
                    this.loading=true;
                    this._submitCandidateEdit(formData);
                }
            },

            _submitCandidateEdit(formData){
                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let education = {
                    ...formData
                };
                if(endDateText == '至今') {
                    education.endDate = '9999-12-31';
                }

                if(this.isSaveToDb) {
                    _request({
                        url: postUrl,
                        method: 'POST',
                        data: {
                            id: this.candidateId,
                            type: this.type,
                            data: {
                                education
                            }    
                        }
                    }).then(res =>{
                        this._cancel();
                        this._refresh();
                        shortTips(isEdit ? '编辑成功' : '添加成功');
                    }).finally(() =>{
                        this.loading=false;
                    })
                } else {
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.loading=false;
                    this.$emit('save-btn-callback', education);
                }
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue(obj){
                let newOjb = {};
                Object.keys(obj).forEach(key =>{
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData(data) {
                Object.assign(this.formData, data);

                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? new Date(): this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? new Date(): this.formData.startDate;
            },

            initEvent(){
                let self = this;
                setTimeout(() =>{
                    this.getUniversityList();
                    this.getMajorList();
                }, 2000)
            },

            
            getUniversityList(){
                if(!localStorage.getItem('universityList')){
                    _request({
                        url: this.universityUrl,
                        method: 'GET'
                    }).then(res => {
                        let items = res;
                        this.universityList = items;
                        localStorage.setItem('universityList', JSON.stringify(items))
                    })
                }else{
                    let items = JSON.parse(localStorage.getItem('universityList'));
                    setTimeout(() =>{
                        this.universityList = items;
                    })
                }
            },
            getMajorList(){
                if(!localStorage.getItem('majorList')){
                    _request({
                        url: this.majorUrl,
                        method: 'GET'
                    }).then(res => {
                        let items = res;
                            this.majorList = items;
                            localStorage.setItem('majorList', JSON.stringify(items))
                    })
                }else{
                    let items = JSON.parse(localStorage.getItem('majorList'));
                    setTimeout(() =>{
                        this.majorList = items;
                    })
                }
            },
            querySchoolSearch(queryString, cb){
                var schools = this.universityList;
                var results = queryString ? schools.filter(this.createFilter(queryString)) : schools;
                cb(results);
            },
            handleSchool(val){
                this.formData.school = val.name;
            },
            queryMajorSearch(queryString, cb) {
                var majors = this.majorList;
                var results = queryString ? majors.filter(this.createFilter(queryString)) : majors;
                cb(results);
            },
            handleMajor(val){
                this.formData.major = val.name;
            },
            createFilter(queryString) {
                return (item) => {
                    return (item.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
        }
    }
</script>

<style lang="scss">
    /*@import '../../../scss/entry/library.scss';*/
    /*@import '../../../scss/entry/common.scss';*/
    /*@import '../../../scss/entry/myCompany.scss';*/
    @import './candidate-resume-education-form.scss';
    .candidate-resume-education-form{
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }

        .el-autocomplete.select-search{
            // width: 320px;
            width: 100%;
            .el-input__inner{
                border: 1px solid #d3d3d3;    
                &:focus {
                    border-color: #d3d3d3;
                }
                &:hover {
                    border-color: #d3d3d3;
                }
            }
        }
    }
    .edit-dialog-autocomplete.el-autocomplete-suggestion{
        margin: 2px 0 0 0;
        .popper__arrow{
            display: none;
        }
        .el-scrollbar__wrap{
            padding: 0;
            max-height: 215px;
            li{
                line-height: 40px;
                height: 40px;
                border-bottom: 1px solid #ececec;
            }
        }
    }
</style>
